import React from "react";
import { createRoot } from "react-dom/client";
import Layout from "./Layout";

// Register Service Worker for PWA support
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(() => console.log("✅ Service Worker Registered"))
      .catch((err) => console.error("❌ Service Worker Error", err));
  });
}

const root = createRoot(document.getElementById("root"));
root.render(<Layout />);


